import { Button, Card, Container, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import Video from "../../components/Video";
import { defaultGreen, defaultPink, defaultYellow } from "../../constants";
import { FaCheckCircle, FaDonate } from "react-icons/fa";
import PayModal from "../../popups/PayModal";
import SuccessDonationModal from "../../popups/SuccessDonationModal";
import { submitDonation } from "../../state/slices/donationSlice";
import { getEpisode } from "../../state/slices/episodeSlice";

const EpisodePage = () => {
  const dispatch = useAppDispatch();
  const { purchasedEpisodes, isAuthenticated } = useAppSelector(
    (state) => state.user
  );
  const { episode } = useAppSelector((state) => state.episodes);
  const { chapterId = "", episodeId = "" } = useParams();
  const [isReproductionDonationOpen, setIsReproductionDonationOpen] =
    useState(false);
  const [successDonationOpen, setSuccessDonationOpen] = useState(false);

  useEffect(() => {
    dispatch(getEpisode({ chapterId, episodeId }));
  }, [chapterId, dispatch, episodeId]);

  return (
    <>
      <SuccessDonationModal
        title=""
        description="Thanks for Donating!"
        isOpen={successDonationOpen}
        setIsOpen={setSuccessDonationOpen}
        icon={<FaCheckCircle size={40} color={defaultGreen} />}
      />
      <PayModal
        price="4.99"
        title="Episode Reproduction Donation"
        isCompleted="NOT"
        isOpen={isReproductionDonationOpen}
        setIsCompleted={() => "NOT"}
        setIsOpen={setIsReproductionDonationOpen}
        description="Stacey Episode Reproduction Donation"
        successMessage="Thank you for donating"
        failedMessage="Purchase failed. Try again."
        onSuccess={() => {
          dispatch(
            submitDonation({
              message: "Stacey episode reproduction donation",
              donationType: "Reproduction",
              image: "No Image",
              amount: "4.99",
            })
          );
          setIsReproductionDonationOpen(false);
          setSuccessDonationOpen(true);
        }}
      />
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <>
          <Title mt={10} c="pink" mb={10} order={2}>
            {episode.name}
          </Title>

          <Video
            id={episodeId}
            chapterId={chapterId}
            thumbnail={episode.thumbnail}
            purchasedEpisodes={purchasedEpisodes}
            url={episode.video}
            createdAt={episode.createdAt === null ? "" : episode.createdAt}
            description={episode.description}
          />

          <Card style={{ backgroundColor: defaultPink }} mt={20} radius="lg">
            <Text size="xl" ta="left" color={defaultYellow}>
              Liking the content so far?
            </Text>
            <Text mt={6} color={defaultYellow}>
              Click the button below, motherfucker! Enjoy this suck-worthy
              episode.
            </Text>

            <Button
              mt={10}
              leftSection={<FaDonate />}
              color="blue"
              radius="xl"
              size="md"
              onClick={() => setIsReproductionDonationOpen(true)}
              fullWidth
            >
              Donate Now | 4.99 USD
            </Button>
          </Card>
        </>
        {/* )} */}
      </Container>
    </>
  );
};

export default EpisodePage;
