import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import {
  Box,
  Button,
  Container,
  Divider,
  FileInput,
  Flex,
  Grid,
  Image,
  Modal,
  Select,
  Table,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import {
  FaDonate,
  FaFileVideo,
  FaPaintRoller,
  FaPlusCircle,
  FaVideo,
} from "react-icons/fa";
import { useForm } from "@mantine/form";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  createChapterWithImage,
  createEpisodeWithImage,
  getEpisodes,
} from "../../state/slices/episodeSlice";
import Loader from "../../components/Loader";
import { Art, Donation, Episode, Episodes } from "../../interfaces";
import moment from "moment";
import { getDonations } from "../../state/slices/donationSlice";
import FanArtCard from "../../components/FanartCard";
import { getAllFanart } from "../../state/slices/fanartSlice";

interface ChapterFormDetails {
  name: string;
  image: File | null;
  description: string;
}

interface ChapterFormWithImageDetails {
  id: string;
  name: string;
  thumbnail: File | null;
  video: string;
  description: string;
}

interface DropDownChapters {
  value: string;
  label: string;
}

const Admin = () => {
  const dispatch = useAppDispatch();
  const { loading, episodes } = useAppSelector((state) => state.episodes);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const { donations } = useAppSelector((state) => state.donations);
  const { allFanart, loading: fanartLoading } = useAppSelector(
    (state) => state.fanart
  );

  const [opened, setIsOpened] = useState(false);
  const [episodesModelOpened, setEpisodesModelOpened] = useState(false);
  const [selectedEpisodes, setSelectedEpisodes] = useState<Episode[]>([]);
  const [mode, setMode] = useState<"CHAPTER" | "EPISODE">("CHAPTER");

  const isValidUrl = (value: string) => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  const chapterForm = useForm({
    initialValues: {
      name: "",
      image: null,
      description: "",
    },

    validate: {
      name: (value: string) =>
        value?.length < 1 ? "Name should be longer" : null,
      image: (value: string) =>
        value === null ? "Image cannot be empty" : null,
      description: (value: string) =>
        value?.length < 4 ? "Description should be longer" : null,
    },
  });

  const episodeForm = useForm({
    initialValues: {
      id: "",
      name: "",
      thumbnail: null,
      video: "",
      description: "",
    },

    validate: {
      id: (value: string) =>
        value?.length === 0 ? "Chapter should be selected" : null,
      name: (value: string) =>
        value?.length < 1 ? "Name should be longer" : null,
      thumbnail: (value: string) =>
        value === null ? "Thumbnail cannot be empty" : null,
      video: (value: string) => (isValidUrl(value) ? null : "Invalid URL"),
      description: (value: string) =>
        value?.length < 4 ? "Description should be longer" : null,
    },
  });

  const handleCreateChapter = (values: ChapterFormDetails) => {
    setIsOpened(true);
    dispatch(
      createChapterWithImage({
        name: values.name,
        description: values.description,
        file: values.image,
      })
    );
    if (!loading) {
      setIsOpened(false);
      chapterForm.reset();
    }
  };

  const handleCreateEpisode = (values: ChapterFormWithImageDetails) => {
    dispatch(
      createEpisodeWithImage({
        id: values.id,
        name: values.name,
        video: values.video,
        description: values.description,
        file: values.thumbnail,
      })
    );
    if (!loading) {
      setIsOpened(false);
      episodeForm.reset();
    }
  };

  const getDropdownEpisodes = () => {
    const selectEpisodes: DropDownChapters[] = [];

    (episodes || []).map((e: Episodes) => {
      return selectEpisodes.push({
        value: e._id,
        label: e.name,
      });
    });
    return selectEpisodes;
  };

  const episodeRows = episodes.map((element: Episodes) => (
    <tr key={element._id}>
      <td>{element.name}</td>
      <td>{element.description.substring(0, 30) + "..."}</td>
      <td>{moment(element.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
      <td>{moment(element.updatedAt).format("DD-MM-YYYY hh:mm A")}</td>
      {element.episodes.length > 1 ? (
        <>
          <td>{element.episodes.length}</td>
        </>
      ) : (
        <td>No Episodes</td>
      )}
      <td style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="filled"
          radius="xl"
          disabled={element.episodes.length ? false : true}
          color="green"
          onClick={() => {
            setEpisodesModelOpened(true);

            setSelectedEpisodes(element.episodes);
          }}
        >
          <FaVideo />
        </Button>
      </td>
    </tr>
  ));

  const donationRows = donations.map((element: Donation) => (
    <tr key={element._id}>
      <td>{element.name}</td>
      <td>{element.email}</td>
      <td>{element.donationType}</td>
      <td>{element.amount}</td>
      <td>{element.image}</td>
      <td>{moment(element.createdAt).format("DD-MM-YYYY hh:mm A")}</td>
    </tr>
  ));

  useEffect(() => {
    dispatch(getEpisodes());
    dispatch(getDonations());
    dispatch(getAllFanart());
  }, [dispatch]);

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setIsOpened(false);
          chapterForm.reset();
        }}
        title={mode === "CHAPTER" ? "Create Chapter" : "Create Episode"}
        radius="lg"
        centered
        closeOnClickOutside={false}
      >
        {mode === "CHAPTER" ? (
          <form
            onSubmit={chapterForm.onSubmit((values: any) =>
              handleCreateChapter(values)
            )}
          >
            <TextInput
              radius="xl"
              withAsterisk
              label="Name"
              placeholder="Chapter Name"
              {...chapterForm.getInputProps("name")}
            />
            <Textarea
              mt={10}
              radius="xl"
              withAsterisk
              label="Description"
              placeholder="Chapter Description"
              {...chapterForm.getInputProps("description")}
            />
            <FileInput
              mt={10}
              radius="xl"
              label="Chapter Image"
              accept="image/png,image/jpeg"
              withAsterisk
              {...chapterForm.getInputProps("image")}
            />
            <Button
              loading={loading}
              mt={12}
              color="pink"
              radius="xl"
              fullWidth
              type="submit"
            >
              Create Chapter
            </Button>
          </form>
        ) : (
          <form
            onSubmit={episodeForm.onSubmit((values: any) => {
              handleCreateEpisode(values);
            })}
          >
            <TextInput
              radius="xl"
              withAsterisk
              label="Episode Name"
              placeholder="Episode Name"
              {...episodeForm.getInputProps("name")}
            />
            <FileInput
              mt={10}
              radius="xl"
              label="Episode Thumbnail"
              withAsterisk
              accept="image/png,image/jpeg"
              {...episodeForm.getInputProps("thumbnail")}
            />
            <TextInput
              mt={10}
              radius="xl"
              withAsterisk
              label="Episode Video"
              placeholder="Episode Video"
              {...episodeForm.getInputProps("video")}
            />
            <Select
              radius="xl"
              label="Select Episode Chapter"
              placeholder="Episode Chapter"
              data={getDropdownEpisodes()}
              {...episodeForm.getInputProps("id")}
            />
            <Textarea
              mt={10}
              radius="xl"
              withAsterisk
              label="Episode Description"
              placeholder="Episode Description"
              {...episodeForm.getInputProps("description")}
            />
            <Button
              loading={loading}
              mt={12}
              color="pink"
              radius="xl"
              fullWidth
              type="submit"
            >
              Create Episode
            </Button>
          </form>
        )}
      </Modal>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container mt={10}>
        <Tabs color="pink" variant="default" defaultValue="videos">
          <Tabs.List>
            <Tabs.Tab value="videos" leftSection={<FaFileVideo />}>
              Videos
            </Tabs.Tab>
            <Tabs.Tab value="donations" leftSection={<FaDonate />}>
              Donations
            </Tabs.Tab>
            <Tabs.Tab value="fanart" leftSection={<FaPaintRoller />}>
              Fanart
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="videos">
            <Box mt={30}>
              <Flex direction="row">
                <Button
                  leftSection={<FaPlusCircle />}
                  color="pink"
                  onClick={() => {
                    setMode("CHAPTER");
                    setIsOpened(true);
                  }}
                  mr={10}
                  radius="xl"
                  fullWidth
                  loading={loading}
                >
                  Create Chapter
                </Button>
                <Button
                  leftSection={<FaPlusCircle />}
                  radius="xl"
                  fullWidth
                  loading={loading}
                  onClick={() => {
                    setMode("EPISODE");
                    setIsOpened(true);
                  }}
                >
                  Create Episode
                </Button>
              </Flex>
            </Box>
            <Divider size="xl" mt={20} label="Chapters & Episodes" />
            <Modal
              opened={episodesModelOpened}
              onClose={() => {
                setEpisodesModelOpened(false);
                setSelectedEpisodes([]);
              }}
              title="Chapter Episodes"
              radius="lg"
              centered
            >
              <>
                {selectedEpisodes.map((e: Episode) => {
                  return (
                    <Box>
                      <Flex>
                        <Flex direction="row">
                          <Image
                            radius="xs"
                            height="40"
                            width="40"
                            fit="contain"
                            src={e.thumbnail}
                            mr={10}
                          />
                        </Flex>
                        <Flex direction="column" justify="center">
                          <Text size="xs">{e.name}</Text>
                          <Text color="dimmed" size="xs">
                            {e.description}
                          </Text>
                          <Text color="dimmed" size="xs">
                            {moment(e.createdAt).format("DD-MM-YYYY hh:mm A")}
                          </Text>
                        </Flex>
                      </Flex>
                      <Divider />
                    </Box>
                  );
                })}
              </>
            </Modal>
            {loading ? (
              <Loader />
            ) : (
              <Table mt={30}>
                <thead>
                  <tr>
                    <th>Chapter Name</th>
                    <th>Chapter Description</th>
                    <th>Published On</th>
                    <th>Last Updated</th>
                    <th>Episodes</th>
                    <th>View Episodes</th>
                  </tr>
                </thead>
                <tbody>{episodeRows}</tbody>
              </Table>
            )}
          </Tabs.Panel>

          <Tabs.Panel value="donations">
            <Divider mt={20} label="Donations" />
            <Table>
              <thead>
                <tr>
                  <th>Donator</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Amount Paid</th>
                  <th>Comments</th>
                  <th>Donated On</th>
                </tr>
              </thead>
              <tbody>{donationRows}</tbody>
            </Table>
          </Tabs.Panel>

          <Tabs.Panel value="fanart">
            <Divider mt={20} label="Fanart" />
            {fanartLoading ? (
              <Flex direction="row" justify="center" align="center">
                <Loader />
              </Flex>
            ) : (
              <Grid mt={10}>
                {(allFanart || []).map((art: Art) => {
                  return (
                    <Grid.Col span={{ base: 4, xs: 12, sm: 6, md: 4 }}>
                      <FanArtCard
                        _id={art._id}
                        image={art.image}
                        name={art.name}
                        date={art.createdAt}
                        approved={art.approved}
                      />
                    </Grid.Col>
                  );
                })}
              </Grid>
            )}
          </Tabs.Panel>
        </Tabs>
      </Container>
    </>
  );
};

export default Admin;
