import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

interface OrderDetails {
  price: string;
  description: string;
}

export const createPayment = createAsyncThunk(
  "payment/create",
  async (orderDetails: OrderDetails, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_URL}/api/payment/create-payment`,
        orderDetails,
        { withCredentials: true }
      );

      const data = await response.data;
      if (response) {
        return data.id;
      } else {
        return rejectWithValue(data.error);
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      } else {
        console.error("Unexpected error:", error);
      }
    }
  }
);

export const executePayment = createAsyncThunk(
  "payment/execute",
  async (orderID: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state?.user?.token}`,
      },
    };

    const response = await axios.post(
      "/api/payment/execute-payment",
      {
        orderId: orderID,
      },
      config
    );
    const data = await response.data;
    if (response) {
      return data.capture;
    } else {
      return rejectWithValue(data.error);
    }
  }
);

const paymentSlice = createSlice({
  name: "auth",
  initialState: {
    success: false,
    loading: false,
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(executePayment.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(executePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.success = true;
      })
      .addCase(executePayment.rejected, (state) => {
        state.loading = false;
        state.error = "An error occurred";
      })
      .addCase(createPayment.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.success = true;
      })
      .addCase(createPayment.rejected, (state) => {
        state.loading = false;
        state.error = "An error occurred";
      });
  },
});

export default paymentSlice.reducer;
