import {
  Flex,
  Box,
  Title,
  Button,
  Avatar,
  Popover,
  ActionIcon,
  Tooltip,
  Anchor,
} from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router-dom";
import { logout } from "../../state/slices/authSlice";
import {
  FaAngleRight,
  FaDonate,
  FaHeart,
  FaSignOutAlt,
  FaTools,
  FaUser,
  FaVideo,
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { defaultBlue } from "../../constants";
import { NavigationBarContainer } from "./styles";
import { useMediaQuery } from "@mantine/hooks";

interface NavigationBarProps {
  isAuthenticated: boolean | null;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { name, isAdmin, loading } = useAppSelector((state) => state.user);
  const smallScreen = useMediaQuery("(max-width: 600px)");

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <NavigationBarContainer>
      <Flex
        style={{ height: "100%" }}
        mx={10}
        align="center"
        justify="space-between"
      >
        <Flex align="center">
          <Anchor
            style={{ fontSize: 22, color: defaultBlue, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Stacey
          </Anchor>
          {smallScreen ? (
            <>
              <Tooltip color="blue" label="Episodes" withArrow>
                <ActionIcon
                  ml={20}
                  size="lg"
                  variant="filled"
                  aria-label="Episodes"
                  radius="lg"
                  onClick={() => navigate("/episodes")}
                  color="pink"
                >
                  <FaVideo />
                </ActionIcon>
              </Tooltip>
              <Tooltip color="pink" label="Donations" withArrow>
                <ActionIcon
                  ml={6}
                  size="lg"
                  variant="filled"
                  aria-label="Episodes"
                  radius="lg"
                  onClick={() => navigate("/donations")}
                  color="pink"
                >
                  <FaDonate />
                </ActionIcon>
              </Tooltip>
              <Tooltip color="blue" label="fuockroom" withArrow>
                <ActionIcon
                  ml={6}
                  size="lg"
                  variant="filled"
                  aria-label="Episodes"
                  radius="lg"
                  onClick={() => navigate("/fuockroom")}
                  color="pink"
                >
                  <FaMessage />
                </ActionIcon>
              </Tooltip>
              <Tooltip color="pink" label="Fanart" withArrow>
                <ActionIcon
                  ml={6}
                  size="lg"
                  variant="filled"
                  aria-label="Episodes"
                  radius="lg"
                  onClick={() => navigate("/fanart")}
                  color="pink"
                >
                  <FaHeart />
                </ActionIcon>
              </Tooltip>
            </>
          ) : (
            <>
              <Anchor
                c="pink"
                ml={8}
                mr={8}
                underline="never"
                onClick={() => navigate("/episodes")}
              >
                Episodes
              </Anchor>
              <Anchor
                c="pink"
                mr={8}
                underline="never"
                onClick={() => navigate("/donations")}
              >
                Donations
              </Anchor>
              <Anchor
                c="pink"
                mr={8}
                underline="never"
                onClick={() => navigate("/fuockroom")}
              >
                Fuockroom
              </Anchor>
              <Anchor
                c="pink"
                mr={8}
                underline="never"
                onClick={() => navigate("/fanart")}
              >
                Fanart
              </Anchor>
            </>
          )}
        </Flex>

        {!isAuthenticated && !loading ? (
          <>
            {smallScreen ? (
              <ActionIcon
                ml={6}
                size="lg"
                variant="filled"
                aria-label="LogIn"
                radius="lg"
                onClick={() => navigate("/auth")}
                color="pink"
              >
                <FaAngleRight />
              </ActionIcon>
            ) : (
              <Button
                size="md"
                radius="xl"
                color="pink"
                rightSection={<FaAngleRight />}
                onClick={() => navigate("/auth")}
              >
                Log In
              </Button>
            )}
          </>
        ) : (
          <Popover radius="lg" position="bottom" withArrow shadow="xl">
            <Popover.Target>
              <Box style={{ cursor: "pointer" }}>
                <Flex align="center">
                  <Avatar size="md" color="blue" variant="filled" radius="xl">
                    {" "}
                    <FaUser />
                  </Avatar>
                </Flex>
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Flex justify="center" align="center" direction="column">
                <Title order={4}>{name}</Title>
                <Button
                  leftSection={<FaSignOutAlt />}
                  mt={6}
                  radius="xl"
                  color="red"
                  fullWidth
                  loading={loading}
                  onClick={() => handleLogout()}
                >
                  Log Out
                </Button>
                {isAdmin && (
                  <Button
                    leftSection={<FaTools />}
                    mt={10}
                    radius="xl"
                    color="green"
                    fullWidth
                    onClick={() => navigate("/admin")}
                  >
                    Administrator
                  </Button>
                )}
              </Flex>
            </Popover.Dropdown>
          </Popover>
        )}
      </Flex>
    </NavigationBarContainer>
  );
};

export default NavigationBar;
