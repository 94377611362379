import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ChapterDetails,
  CreateChapterDetails,
  CreateEpisodeDetails,
  EpisodeDetails,
} from "../../interfaces";
import { notifications } from "@mantine/notifications";

interface EpisodeFetch {
  episodeId: string;
  chapterId: string;
}

export const getEpisodes = createAsyncThunk("episodes/getAll", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/episodes`
  );
  return response.data;
});

export const createChapter = createAsyncThunk(
  "episodes/createChapter",
  async (details: ChapterDetails, { dispatch }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/episodes`,
      {
        name: details.name,
        image: details.image,
        description: details.description,
      },
      { withCredentials: true }
    );
    dispatch(getEpisodes());
    return response.data;
  }
);

export const createChapterWithImage = createAsyncThunk(
  "episodes/createChapterWithImage",
  async (details: CreateChapterDetails, { dispatch }) => {
    const formData = new FormData();
    if (details.file !== null) {
      formData.append("image", details.file);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(
      createChapter({
        name: details.name,
        image: response.data.url,
        description: details.description,
      })
    );
    return response.data;
  }
);

export const createEpisode = createAsyncThunk(
  "episodes/createEpisode",
  async (details: EpisodeDetails) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/episodes/${details.id}`,
      {
        episodeName: details.episodeName,
        thumbnail: details.thumbnail,
        video: details.video,
        price: details.price,
        visible: details.visible,
        episodeDescription: details.episodeDescription,
        watchCountOfUser: details.watchCountOfUser,
        previouslyWatched: details.previouslyWatched,
      },
      { withCredentials: true }
    );
    return response.data;
  }
);

export const createEpisodeWithImage = createAsyncThunk(
  "episodes/createEpisodeWithImage",
  async (details: CreateEpisodeDetails, { dispatch }) => {
    const formData = new FormData();
    if (details.file !== null) {
      formData.append("image", details.file);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    dispatch(
      createEpisode({
        id: details.id,
        episodeName: details.name,
        thumbnail: response.data.url,
        video: details.video,
        price: "4.99",
        visible: true,
        episodeDescription: details.description,
        watchCountOfUser: 0,
        previouslyWatched: false,
      })
    );
    return response.data;
  }
);

export const getEpisode = createAsyncThunk(
  "episodes/get",
  async (fetchDetails: EpisodeFetch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BE_URL}/api/episodes/${fetchDetails.chapterId}/${fetchDetails.episodeId}`,
      config
    );
    return response.data;
  }
);

const episodeSlice = createSlice({
  name: "fanart",
  initialState: {
    episode: {
      name: "",
      thumbnail: "",
      video: "",
      price: 0,
      visible: true,
      description: "",
      watchCountOfUser: 0,
      previouslyWatched: false,
      _id: "",
      createdAt: null,
      updatedAt: null,
    },
    episodes: [],
    loading: false,
    success: false,
  },
  reducers: {
    selectEpisode: (state, action) => {
      state.episode.name = action.payload.id;
      state.episode.thumbnail = action.payload.thumbnail;
      state.episode.video = action.payload.video;
      state.episode.price = action.payload.price;
      state.episode.visible = action.payload.visible;
      state.episode.description = action.payload.description;
      state.episode.watchCountOfUser = action.payload.watchCountOfUser;
      state.episode.previouslyWatched = action.payload.previouslyWatched;
      state.episode._id = action.payload._id;
      state.episode.createdAt = action.payload.createdAt;
      state.episode.updatedAt = action.payload.updatedAt;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEpisodes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEpisodes.fulfilled, (state, action) => {
        state.loading = false;
        state.episodes = action.payload;
      })
      .addCase(getEpisodes.rejected, (state, action) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to get Episodes!",
        });
      })
      .addCase(getEpisode.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEpisode.fulfilled, (state, action) => {
        state.loading = false;
        state.episode = action.payload;
      })
      .addCase(getEpisode.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to load episode!",
        });
      })
      .addCase(createChapter.pending, (state) => {
        state.loading = true;
      })
      .addCase(createChapter.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createChapter.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to create chapter!",
        });
      })
      .addCase(createChapterWithImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(createChapterWithImage.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createChapterWithImage.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to upload chapter thumbnail!",
        });
      })
      .addCase(createEpisode.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEpisode.fulfilled, (state) => {
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Success!🙂",
          message: "Episode created!",
        });
        state.loading = false;
      })
      .addCase(createEpisode.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to create episode!",
        });
      })
      .addCase(createEpisodeWithImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEpisodeWithImage.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createEpisodeWithImage.rejected, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to upload episode thumbnail!",
        });
      });
  },
});

export const { selectEpisode } = episodeSlice.actions;
export default episodeSlice.reducer;
