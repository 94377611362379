import { Button, Card, Container, Flex, Image, Text } from "@mantine/core";
import moment from "moment";
import React from "react";
import { defaultPink } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { approveFanart } from "../../state/slices/fanartSlice";
import { useLocation } from "react-router-dom";

interface FanArtCardProps {
  image: string;
  _id: string;
  name: string;
  date: Date;
  approved: boolean;
}

const FanArtCard: React.FC<FanArtCardProps> = ({
  image,
  name,
  _id,
  date,
  approved,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const containsAdmin = location.pathname.includes("/admin");

  const { isAdmin } = useAppSelector((state) => state.user);
  return (
    <Container key={_id}>
      <Card style={{ backgroundColor: defaultPink }} radius="lg">
        <Flex justify="center">
          <Image fit="contain" radius="md" w={300} h={300} src={image} />
        </Flex>
      </Card>
      {containsAdmin && (
        <Card mt={5} style={{ backgroundColor: defaultPink }} radius="lg">
          <Text c="yellow">By {name}</Text>
          <Text c="yellow">On {moment(date).format("DD-MM-YYYY hh:mm A")}</Text>
          {isAdmin && (
            <Button
              mt={10}
              radius="xl"
              color={approved ? "red" : "green"}
              onClick={() => dispatch(approveFanart(_id))}
            >
              {approved ? "Unapprove" : "Approve"}
            </Button>
          )}
        </Card>
      )}
    </Container>
  );
};

export default FanArtCard;
