import { useEffect } from "react";
import {
  Accordion,
  Container,
  Flex,
  Grid,
  Title,
  Text,
  Loader,
  Card,
} from "@mantine/core";
import EpisodeCard from "../../components/EpisodeCard";
import NavigationBar from "../../components/NavigationBar";
import { useMediaQuery } from "@mantine/hooks";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { Episode, Episodes } from "../../interfaces";
import { getEpisodes } from "../../state/slices/episodeSlice";
import { defaultPink, defaultYellow } from "../../constants";
import "./styles.css";
import Empty from "../../components/Empty";
import { FaVideo } from "react-icons/fa";

const EpisodesPage = () => {
  const dispatch = useAppDispatch();
  const { episodes, loading } = useAppSelector((state) => state.episodes);
  const { isAuthenticated } = useAppSelector((state) => state.user);

  const smallScreen = useMediaQuery("(max-width: 600px)");

  const items = episodes.map((item: Episodes) => (
    <Accordion.Item
      mt={10}
      style={{ backgroundColor: defaultPink, borderRadius: "1rem" }}
      key={item._id}
      value={item._id}
    >
      <Accordion.Control>
        <Text color="yellow">{item.name}</Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Card
          style={{ backgroundColor: defaultPink }}
          radius="lg"
          shadow="xl"
          mt={10}
        >
          <Flex align="center" direction="column">
            <img
              style={{
                height: smallScreen ? "10rem" : "18rem",
                borderRadius: "2rem",
              }}
              src={item.image}
              alt=""
            />
          </Flex>
          <Text my={20} c="white">
            {item.description}
          </Text>

          <Text size="lg" my={20} c="yellow">
            Episodes in this chapter
          </Text>
          <Grid mt={10}>
            {item.episodes.length < 1 ? (
              <Text color="yellow">This chapter doesnt have any episodes</Text>
            ) : (
              <>
                {item.episodes.map((episode: Episode) => {
                  return (
                    <Grid.Col span={{ base: 4, xs: 12, sm: 6, md: 4 }}>
                      <EpisodeCard
                        _id={episode._id}
                        title={episode.name}
                        description={episode.description}
                        image={episode.thumbnail}
                        createdAt={episode.createdAt}
                        price={episode.price}
                        chapterId={item._id}
                      />
                    </Grid.Col>
                  );
                })}
              </>
            )}
          </Grid>
        </Card>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  useEffect(() => {
    dispatch(getEpisodes());
  }, [dispatch]);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Flex my={20} justify="center">
          <Title c="pink" order={1}>
            Episodes
          </Title>
        </Flex>
        {loading ? (
          <Flex direction="row" justify="center" align="center">
            <Loader size="xl" color="pink" />
          </Flex>
        ) : (
          <>
            {items.length ? (
              <>
                <Accordion
                  radius="xl"
                  chevronPosition="right"
                  defaultValue="customization"
                  chevron={<FaVideo color={defaultYellow} />}
                >
                  {items}
                </Accordion>
              </>
            ) : (
              <Empty title="No Episodes Found..." />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default EpisodesPage;
