import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { DonationDetails, TweetDetails } from "../../interfaces";
import { notifications } from "@mantine/notifications";

export const getDonations = createAsyncThunk("donations/get", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BE_URL}/api/donation`,
    { withCredentials: true }
  );
  return response.data;
});

export const tweetDonation = createAsyncThunk(
  "donations/tweet",
  async (details: TweetDetails) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/tweet`,
      details,
      { withCredentials: true }
    );
    return response.data;
  }
);

export const submitDonation = createAsyncThunk(
  "donations/submit",
  async (details: DonationDetails) => {
    console.log(details);
    const response = await axios.post(
      `${process.env.REACT_APP_BE_URL}/api/donation`,
      details,
      { withCredentials: true }
    );
    return response.data;
  }
);
const donationSlice = createSlice({
  name: "donation",
  initialState: {
    donations: [],
    loading: false,
    error: null as true | false | null,
  },
  reducers: {
    resetState: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDonations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDonations.fulfilled, (state, action) => {
        state.loading = false;
        state.donations = action.payload;
      })
      .addCase(getDonations.rejected, (state) => {
        state.loading = false;
      })
      .addCase(tweetDonation.pending, (state) => {
        state.loading = true;
      })
      .addCase(tweetDonation.fulfilled, (state) => {
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!😭",
          message: "Error tweeting!",
        });
      })
      .addCase(tweetDonation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(submitDonation.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(submitDonation.fulfilled, (state) => {
        state.error = false;
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "green",
          title: "Thanks!🙂",
          message: "Thanks for your donation!",
        });
      })
      .addCase(submitDonation.rejected, (state) => {
        state.error = true;
        state.loading = false;
        notifications.show({
          position: "top-right",
          radius: "lg",
          color: "red",
          title: "Oops!🙂",
          message: "Failed to submit donation!",
        });
      });
  },
});

export default donationSlice.reducer;
