import {
  Avatar,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Text,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useNavigate } from "react-router-dom";
import { defaultGreen, defaultPink } from "../../constants";
import PayModal from "../../popups/PayModal";
import { submitDonation } from "../../state/slices/donationSlice";
import { FaCheckCircle, FaDonate } from "react-icons/fa";
import SuccessDonationModal from "../../popups/SuccessDonationModal";
import { SelectedDonationTier } from "../../interfaces";

const DonationPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token, isAuthenticated } = useAppSelector((state) => state.user);
  const [isDonatedModalOpen, setIsDonatedModalOpen] = useState(false);
  const [successDonationOpen, setSuccessDonationOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState<SelectedDonationTier>({
    price: "0",
    title: "",
    description: "",
    success: "",
    failed: "",
    message: "",
    donationType: "Tweet",
    image: "",
    amount: "",
  });

  const handleOnDonationSuccess = async () => {
    await dispatch(
      submitDonation({
        message: selectedTier.description,
        donationType: selectedTier.donationType,
        image: "",
        amount: "5.00",
      })
    );
    setSelectedTier({
      price: "0",
      title: "",
      description: "",
      success: "",
      failed: "",
      message: "",
      donationType: "Tweet",
      image: "",
      amount: "",
    });

    setIsDonatedModalOpen(false);
    setSuccessDonationOpen(true);
  };

  useEffect(() => {
    if (token === "") {
      navigate("/auth");
    }
  }, [dispatch, token, navigate]);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Flex my={20} justify="center">
          <Title order={1} c="pink">
            Donation Tiers
          </Title>
        </Flex>
        <SuccessDonationModal
          title=""
          description="Thanks for Donating!"
          isOpen={successDonationOpen}
          setIsOpen={setSuccessDonationOpen}
          icon={<FaCheckCircle size={40} color={defaultGreen} />}
        />
        <PayModal
          price={selectedTier.price}
          title={selectedTier.title}
          isCompleted="NOT"
          isOpen={isDonatedModalOpen}
          setIsCompleted={() => "NOT"}
          setIsOpen={setIsDonatedModalOpen}
          description={selectedTier.description}
          successMessage={selectedTier.success}
          failedMessage={selectedTier.failed}
          onSuccess={handleOnDonationSuccess}
        />
        <Grid>
          <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12 }}>
            <Card
              style={{ backgroundColor: defaultPink }}
              shadow="xl"
              radius="lg"
            >
              <Flex direction="column" align="center" justify="center">
                <Avatar
                  color="blue"
                  mr={4}
                  variant="light"
                  size="lg"
                  radius="xl"
                >
                  1
                </Avatar>
                <Text c="yellow" size="xl" ta="center">
                  Tweet
                </Text>
                <Text c="white" size="lg" ta="center">
                  Want a tweet from a character?
                </Text>
                <Button
                  mt={10}
                  leftSection={<FaDonate />}
                  color="blue"
                  radius="xl"
                  size="md"
                  onClick={() => {
                    setSelectedTier({
                      price: "5.00",
                      title: "Tweet from a character",
                      description: "Stacey donation - tweet from a character",
                      success:
                        "Thank you. You will see the tweet on Stacey twitter account soon",
                      failed: "Donation failed. Try again.",
                      message: "Stacey donation - tweet from a character",
                      donationType: "Tweet",
                      image: "Tweet",
                      amount: "5.00",
                    });
                    setIsDonatedModalOpen(true);
                  }}
                  fullWidth
                >
                  Donate | 5.00 USD
                </Button>
              </Flex>
            </Card>
          </Grid.Col>

          <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12 }}>
            <Card
              style={{ backgroundColor: defaultPink }}
              shadow="xl"
              radius="lg"
            >
              <Flex justify="center">
                <Avatar
                  color="blue"
                  mr={4}
                  variant="light"
                  size="lg"
                  radius="xl"
                >
                  2
                </Avatar>
              </Flex>
              <Text c="yellow" size="xl" ta="center">
                Character Creation
              </Text>
              <Text c="white" size="lg" ta="center">
                What about your own character?
              </Text>

              <Flex justify="center" mt={10}>
                <Button
                  leftSection={<FaDonate />}
                  color="blue"
                  radius="xl"
                  size="md"
                  onClick={() => {
                    setSelectedTier({
                      price: "100.00",
                      title: "Get your own character",
                      description: "Stacey donation - get your own character",
                      success:
                        "Thank you. You will see the your character on Stacey series soon",
                      failed: "Donation failed. Try again.",
                      message: "Stacey donation - get your own character",
                      donationType: "Character Creation",
                      image: "",
                      amount: "100.00",
                    });
                    setIsDonatedModalOpen(true);
                  }}
                  fullWidth
                >
                  Donate | 100.00 USD
                </Button>
              </Flex>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12 }}>
            <Card
              style={{ backgroundColor: defaultPink }}
              shadow="xl"
              radius="lg"
            >
              <Flex justify="center">
                <Avatar
                  color="blue"
                  mr={4}
                  variant="light"
                  size="lg"
                  radius="xl"
                >
                  3
                </Avatar>
              </Flex>
              <Text c="yellow" size="xl" ta="center">
                Character Appearence
              </Text>
              <Text c="white" size="lg" ta="center">
                Want your avatar to appear?
              </Text>
              <Flex justify="center" mt={10}>
                <Button
                  leftSection={<FaDonate />}
                  color="blue"
                  radius="xl"
                  size="md"
                  onClick={() => {
                    setSelectedTier({
                      price: "1000.00",
                      title: "Get a character appearence",
                      description:
                        "Stacey donation - see a character appearence",
                      success:
                        "Thank you. You will see the a character appearence soon",
                      failed: "Donation failed. Try again.",
                      message: "Stacey donation - see a character appearence",
                      donationType: "Character Appearence",
                      image: "Character",
                      amount: "1000.00",
                    });
                    setIsDonatedModalOpen(true);
                  }}
                  fullWidth
                >
                  Donate | 1000.00 USD
                </Button>
              </Flex>
            </Card>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default DonationPage;
