import { Button, Container, Grid } from "@mantine/core";
import NavigationBar from "../../components/NavigationBar";
import { Box, Flex, Image, Text } from "@mantine/core";
import onlyfgs from "../../images/Onlyfags.png";
import character from "../../images/Character.png";
import fuockrum from "../../images/Fuockrum.png";
import donations from "../../images/Donation.png";
import episodes from "../../images/Episodes.png";
import fanart from "../../images/Art.png";
import { useNavigate } from "react-router-dom";
import { defaultYellow } from "../../constants";
import { useAppSelector } from "../../state/hooks";
import { FaArrowRight } from "react-icons/fa";
import { PopUpContainer } from "../../components/NavigationBar/styles";

const HomePage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.user);

  return (
    <>
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Container>
        <Grid mt={30}>
          <Grid.Col span={{ base: 4, xs: 12, sm: 6, md: 12 }}>
            <Flex direction="column" justify="center" align="center">
              <img width={200} height={500} src={character} alt="" />
              <img width={200} height={80} src={onlyfgs} alt="" />
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 8, xs: 12, sm: 6, md: 12 }}>
            <Flex
              h="100%"
              gap="md"
              justify="center"
              align="flex-start"
              direction="column"
              wrap="wrap"
              style={{ transition: "0.15s" }}
            >
              <PopUpContainer>
                <Button
                  color="pink"
                  radius="xl"
                  h={120}
                  rightSection={<FaArrowRight color={defaultYellow} />}
                  fullWidth
                  onClick={() => navigate("/episodes")}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: defaultYellow,
                      borderRadius: "50%",
                      width: 80,
                      height: 80,
                    }}
                  >
                    <Image fit="contain" w={90} height={90} src={episodes} />
                  </Box>
                  <Text color="yellow" ml={10} size="24">
                    Episodes
                  </Text>
                </Button>
              </PopUpContainer>
              <PopUpContainer>
                <Button
                  color="pink"
                  radius="xl"
                  h={120}
                  rightSection={<FaArrowRight color={defaultYellow} />}
                  fullWidth
                  onClick={() => navigate("/donations")}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: defaultYellow,
                      borderRadius: "50%",
                      width: 80,
                      height: 80,
                    }}
                  >
                    <Image fit="contain" w={90} height={90} src={donations} />
                  </Box>
                  <Text color="yellow" ml={10} size="24">
                    Donations
                  </Text>
                </Button>
              </PopUpContainer>
              <PopUpContainer>
                <Button
                  color="pink"
                  radius="xl"
                  h={120}
                  rightSection={<FaArrowRight color={defaultYellow} />}
                  fullWidth
                  onClick={() => navigate("/fuockroom")}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: defaultYellow,
                      borderRadius: "50%",
                      width: 80,
                      height: 80,
                    }}
                  >
                    <Image
                      radius="xl"
                      fit="contain"
                      w={50}
                      height={50}
                      src={fuockrum}
                    />
                  </Box>
                  <Text color="yellow" ml={10} size="24">
                    fuockroom
                  </Text>
                </Button>
              </PopUpContainer>
              <PopUpContainer>
                <Button
                  color="pink"
                  radius="xl"
                  h={120}
                  rightSection={<FaArrowRight color={defaultYellow} />}
                  fullWidth
                  onClick={() => navigate("/fanart")}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: defaultYellow,
                      borderRadius: "50%",
                      width: 80,
                      height: 80,
                    }}
                  >
                    <Image fit="contain" w={90} height={90} src={fanart} />
                  </Box>
                  <Text color="yellow" ml={10} size="24">
                    Fan Art
                  </Text>
                </Button>
              </PopUpContainer>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
